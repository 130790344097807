<template>
  <div>
    TEST
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {
    console.log(this.$route.query)
  }
}
</script>
